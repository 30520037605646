@import "../vars";

.main-documentation {
    width: 100%;
    width: 100%;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 124px);
    padding: 32px 24px;
    .subhead-documentation {
        color: $grey;
    }
    .body-documentation {
        .search-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }   
        .search-documentation {
            display: flex;
            align-items: center;
        }
        .documentation-results {
            display: flex;
            flex-wrap: wrap;
        }
        .download-documentation {
            display: flex;
            align-items: center;
            padding-top: 32px;
            width: min-content;
            .download-card {
                box-sizing: border-box;
                height: 217px;
                width: 166px;
                border: 1px solid;
                border-color: $light-grey-1;
                border-radius: 2px;
                background-color: $white;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                p {
                    width: 100%;
                    text-align: center;
                    color: $grey;
                    word-wrap: break-word;
                }
            }
            > :first-child {
                margin-right: 24px;
            }
        }
                .download-documentation-rtf {
                    align-items: center;
                    padding-top: 32px;
        
                    .download-card {
                        box-sizing: border-box;
                        height: 217px;
                        width: 166px;
                        border: 1px solid;
                        border-color: $light-grey-1;
                        border-radius: 2px;
                        background-color: $white;
                        padding: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
        
                        p {
                            width: 100%;
                            text-align: center;
                            color: $grey;
                            word-wrap: break-word;
                        }
                    }
        
                    > :first-child {
                        margin-right: 24px;
                    }
                }
        .no-results {
            height: 216px;
            width: 100%;
            padding: 12px 54px;
            border: 1px solid;
            border-color: $light-grey-1;
            opacity: 0.5;
            border-radius: 2px;
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }
        .icon-card {
            margin-bottom: $lg;
        }
        .table-rtf{
                 margin-top: 8px;
                 box-sizing: border-box;
                 border: 1px solid rgba(205, 208, 227, 0.3);
                 border-radius: 8px;
                 background-color: $white;
                 width: 100%;
                 // height: 65vh;
                 overflow-y: auto;}
    }
}
.tool-bar-documentation {
    position: relative;
    height: 100%;
    padding: 40px 32px;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.17);
    background-color: $white;
    display: flex;
    flex-direction: column;
    .line {
        height: $xs;
        border-bottom: 1px solid;
        border-bottom-color: $light-grey-1;
        margin-bottom: 0.75rem;
    }
    .request-button {
        display: flex;
        justify-content: center;
        padding: 32px 0;
    }
    .back-button {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: flex-end;
    }
}

@media only screen and (max-width: 992px) {
    .main-documentation {
        padding: 0 8px;
    
        .body-documentation {
            .search-section {
                display: block;
                justify-content: space-between;
                align-items: center;
            }

            .no-results {
                flex-direction: initial
            }}
    
    
    }
    .tool-bar-documentation {
        padding: 16px;
        border-radius: 8px;
        box-shadow: none;
        .request-button {
            padding: 16px 0;
        }
    }
 
}
