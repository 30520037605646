@import '../vars';

.main-search-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    padding: 24px 32px;
    .search-bar-header {
        border-bottom: 1px solid #E9E9E9;
        .search-bar-reset {
            display: none;
        }
    }
    .search-bar-body {
        display: flex;
        flex-direction: column;
        .inputControlWrapper {
            padding: 0;
            margin-bottom: 1rem;
        }
        .rma-search-bar {
            .inputControlWrapper {
                padding-bottom: 8px;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main-search-bar {
        padding: 16px;
    }
}

@media only screen and (max-width: 62em) {
    .main-search-bar {
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
        .search-bar-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0 10px 0;
            .search-bar-reset {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 0.5rem;
                }
                label {
                    color: $cisco-blue;
                }
            }
        }
    }
}