@import '../vars';

.main-rmas {
    width: 100%;
    padding: 32px 24px;
    .rmas-header {
        padding-bottom: 16px;
    }
}
.rmas-cheked {
    background-color: $white;
    .clearBtn {
        max-width: 14px;
    }
}

.rmas-sm {
    button {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .main-rmas {
        padding: 0;
        .rmas-header {
            button {
                width: 100%;
            }
        }
        .rmas-cheked {
            background-color: $white;
            .clearBtn {
                max-width: 14px;
            }
        }
    }
}