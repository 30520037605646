@import "../vars";

.rmaControlBar {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 32px 24px;
    .rmaControlBarDescription {
        border-bottom: 1px solid #e9e9e9;
        &.space-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        p {
            min-height: 28px;
            color: $grey;
            font-size: 10px;
            letter-spacing: 0;
            line-height: 14px;
        }
    }
    .form-rtf-content {
        margin-bottom: 15vh;
        overflow-y: scroll;
    }
    .label-form-calendar{
        color:$dark-grey-2
    }

    .border-none {
        border: none;
    }
    .rmaControlBarFields {
        margin-bottom: 48px;
        padding-left: 8px;
        padding-right: 8px;
        .inputWithInfo {
            position: relative;
            .infoCircle {
                position: absolute;
                top: 18px;
                right: 0;
            }
        }
        .inputControlWrapper {
            padding: 0;
        }
    }
    .inputSpace {
        margin: 13px 0 18px 0;
    }
    .bottomButton {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 0 24px;
    }
    .btnContainer {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        position: absolute;
        bottom: 32px;

        .widthBtn {
            width: 85%;
        }

        .finishedMessage {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;

            p {
                color: $green;
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .rmaControlBar {
        box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        padding: 16px;
        .rmaControlBarFields {
            .optionsSearchDates {
                flex-direction: column;
                .inputCalendarContainer {
                    input {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 62em) {
    .rmaControlBar {
            .btnContainer {
                display: flex;
                position: relative;
                bottom: 0;
                .widthBtn {
                    width: 45%;
                }
            }
        }
}
