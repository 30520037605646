.label-only {
    padding: 0 32px;
    .modal-head {
        width: 100%;
        max-width: 305px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 4px 0 8px;
        border-bottom: 1px solid $light-grey-1;
    }
    .options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 40px;
        .select-container {
            display: flex;
        }
        .request-selected {
            box-sizing: border-box;
            border: 1px solid $cisco-blue;
            border-radius: 8px;
            background-color: $light-grey-1;
        }
        .request-option {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 8px;
            border-radius: 8px;
            margin: 0 19px;
            height: 130px;
            min-width: 117px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
            .iconSelected {
                height: 14px;
                width: 100%;
                text-align: right;
            }
            .iconType {
                margin: auto;
            }
            p {
                color: $grey;
                margin: 0 auto;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .label-only {
        padding: 0;
        .options-container {
            padding: 0;
            .request-option {
                width: 100%;
                svg {
                    width: 1em;
                }
            }
        }
    }
}
