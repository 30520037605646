@import '../../vars';

.uploadContainer {
    margin: 2rem 0;
    .formContainer {
        border-bottom: 1px solid;
        border-color: $grey;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        .uploadZone {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .UploadForm {
                width: 189px;
                .labelFile {
                    max-width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $cisco-blue;
                    color: $white;
                    height: 28px;
                    min-width: 130px;
                    width: min-content;
                    border-radius: 20px;
                    border-style: solid;
                    border-width: 1px;
                    padding: 0 50px;
                    font-family: 'CiscoSansTT-Regular', sans-serif;
                    font-size: $n;
                    text-align: center;
                    white-space: nowrap;
                    cursor: pointer;
                }
                .inputFile {
                    width: 1px;
                    display: none;
                }
            }
        }
        .message {
            width: 100%;
            text-align: right;
            color: $grey;
            font-size: 10px;
            letter-spacing: 0;
            line-height: 14px;
        }
    }
    .fileContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 1rem;
        box-sizing: border-box;
        height: 64px;
        width: calc(100% - 2rem);
        border: 1px solid;
        border-color: $light-grey-1;
        border-radius: 2px;
        .fileData {
            width: calc(100% - 62px);
            display: flex;
            flex-direction: column;
            .fileNameAExt {
                height: 24px;
                display: flex;
                flex-wrap: nowrap;
                .fileName {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}