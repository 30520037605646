
    .tableFoot {
        padding: 14px 8px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
            color: $dark-grey-1;
        }
        .pageControler {
            display: flex;
            align-items: center;
            .pageArrow {
                margin: 0 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 28px;
                width: 28px;
                border-radius: 4px;
                background-color: $light-grey-1;
            }
            .pageArrowActive {
                cursor: pointer;
            }
            .hover-Btn {
                p:hover {
                    opacity: 1;
                }
            }

            .hover-Btn:hover {
                background-color: rgba($color: $cisco-blue, $alpha: 0.1);
                color: $dark-grey-1;
                
                .hover-icon{
                    color:$cisco-blue !important;
                    opacity: 1;
                }
            }
            .pageBtn {
                cursor: pointer;
                margin: 0 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 33px;
                width: 33px;
                border-radius: 4px;
                color: $grey;
            }
            .pageBtnActive {
                cursor: default;
                color: $white;
                background-color: $cisco-blue;
            }
        }
    }

@media only screen and (max-width: 1199px) {
    .tableFoot {
        flex-direction: column;
    }
}