@import '../../vars';

.dropDownMenu {
    position: relative;
    width: 125px;
    margin: 0;
    .dropDownMenuShow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
            white-space: nowrap;
            height: 24px;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0;
        }
        .dropDownMenuBtn {
            margin: 0 0 0 16px;
        }
    }
    .dropDownMenuContent {
        position: absolute;
        right: 0;
        width: 150px;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid;
        border-color: $light-grey-1;
        background-color: $white;
        z-index: 10;
        .dropDownMenuOption {
            display: flex;
            align-items: center;
            padding: 12px;
            border-bottom: solid 1px $light-grey-1;
            cursor: pointer;
            label {
                margin: 0 0 0 8px;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }
}