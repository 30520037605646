@import "./vars";

@font-face {
    font-family: CiscoSansTT-Light;
    src: url("../static/fonts/CiscoSansTTLight.woff2") format("woff2"),
        url("../static/fonts/CiscoSansTTLight.woff") format("woff");
}

@font-face {
    font-family: CiscoSansTT-Regular;
    src: url("../static/fonts/CiscoSansTTRegular.woff2") format("woff2"),
        url("../static/fonts/CiscoSansTTRegular.woff") format("woff");
}

@font-face {
    font-family: CiscoSansTT-Bold;
    src: url("../static/fonts/CiscoSansTTBold.woff2") format("woff2"),
        url("../static/fonts/CiscoSansTTBold.woff") format("woff");
}

html,
body {
    width: 100%;
    overflow-x: hidden;
    #root {
        width: 100%;
        overflow-x: hidden;
    }
}

body {
    margin: 0;
    font-size: 16px;
    padding: 0 !important;

    *,
    * {
        font-family: CiscoSansTT-Regular;
    }

    h1 {
        font-family: CiscoSansTT-Light;
        font-size: 1.75rem;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $dark-grey-2;
        margin-bottom: 8px;
    }

    h2 {
        font-family: CiscoSansTT-Regular;
        font-size: 1.5rem;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $dark-grey-2;
        margin-bottom: 8px;
    }

    h3 {
        font-family: CiscoSansTT-Bold;
        font-size: 1rem;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $dark-grey-2;
        margin-bottom: 8px;
    }

    h4 {
        font-family: CiscoSansTT-Bold;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        min-height: 20px;
        line-height: 20px;
        letter-spacing: normal;
        color: $dark-grey-2;
        margin-bottom: 16px;
    }

    p {
        font-family: CiscoSansTT-Regular;
        color: $dark-grey-1;
        font-size: 14px;
        letter-spacing: 0;
    }

    label, p.as-label {
        font-family: CiscoSansTT-Regular;
        color: $grey;
        font-size: 0.75rem;
        letter-spacing: 0;
        margin: 0;
        overflow-wrap: break-word;
        hyphens: auto;
    }

    label.strong {
        font-family: CiscoSansTT-Bold;
        color: $dark-grey-1;
        font-size: 0.75rem;
        letter-spacing: 0;
        margin: 0;
    }

    textarea {
        padding: 7px 0.5rem;
        width: 100%;
        height: 56px;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        border-color: transparent;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: $light-grey-1;
        color: $dark-grey-1;
    }

    textarea::placeholder,
    textarea:-ms-input-placeholder,
    textarea::-ms-input-placeholder {
        color: $dark-grey-1;
    }

    label.asterisk {
        color: $red;
        font-family: CiscoSansTT-Regular;
        font-size: 0.75rem;
        letter-spacing: 0;
        margin: 0;
        margin-left: 0.25rem;
        padding-left: 0px !important;
    }

    label.errorMessage {
        margin: 4px 0 0 0;
        padding-left: 1rem;
        height: 1rem;
        font-size: 0.75rem;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: $red !important;
    }

    .centered {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .y-bottom {
        display: flex;
        height: 100%;
        align-items: flex-end;
    }
    
    .y-centered {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .x-centered {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .row-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    textarea:focus,
    input:focus {
        outline: none !important;
        outline-width: 0;
    }

    .pb-32 {
        padding-bottom: 32px;
    }

    .pb-16 {
        padding-bottom: 16px;
    }

    .pb-8 {
        padding-bottom: 8px;
    }

    .pt-16 {
        padding-top: 16px;
    }

    .pr-24 {
        padding-right: 24px;
    }

    .pl-16 {
        padding-left: 16px;
    }

    .py-16 {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .px-16 {
        padding-left: 16px;
        padding-right: 16px;
    }

    .mt-16 {
        margin-top: 16px;
    }

    .mb-8 {
        margin-bottom: 8px;
    }

    .mb-16 {
        margin-bottom: 16px;
    }

    .full-height {
        height: 100%;
    }

    .overlay-spinner {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
        .spinner {
            display: flex;
            align-content: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            align-items: center;
        }
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    div.comments {
        color: $grey;
        font-size: 12px;
        letter-spacing: 0;
    }

    .blue-link {
        color: $cisco-blue;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
            text-decoration-color: currentcolor;
            text-decoration-color: $dark-blue;
            color: $dark-blue !important;
        }
    }

    .clickable {
        cursor: pointer;
        &:hover * {
            color: $dark-blue;
            fill: $dark-blue !important;
            opacity: 1.8;
        }
    }

    .ellipsis {
        word-break: break-word;
        line-break: auto;
        word-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;  
        overflow-wrap: break-word;
        overflow: hidden;
    }

    .package-inputs-container {
        display: flex;
        align-items: center;
        text-align: center;
        white-space: nowrap;
        .sizeInput {
            text-align: center;
            height: 2rem !important;
            // width: 4rem;
            // min-width: 3rem !important;
            // max-width: calc(10ch + 1rem);
            width: calc(8ch + 1rem);
            margin-right: 0.5rem;
        }
        p {
            margin: 0;
        }
    }

    @media only screen and (min-width: 992px) {
        .pr-lg-8 {
            padding-right: 8px;
        }

        .pl-lg-8 {
            padding-left: 8px;
        }
    }
}
* {
    scrollbar-width: thin;
    scrollbar-color: #b5b6b9 white;
}
*::-webkit-scrollbar {
    width: 8px !important;
}
*::-webkit-scrollbar-track {
    background: $light-grey-3;
}
*::-webkit-scrollbar-thumb {
    background-color: #b5b6b9;
    border-radius: 20px;
    border: 3px solid white;
}

.modal-dialog .main-alert-modal {
    width: calc(100% - 2px);
}
header .main-alert .hide-alert {
    display: none;
}
.modal-dialog .main-alert .hide-modal {
    display: none;
}

.border-bottom-gray {
    border-bottom: 1px solid $gray-opacity;
}
