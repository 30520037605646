@import "../../vars";

.buttonControlWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $xl;
    min-width: 130px;
    margin: 0 8px;
    width: min-content;
    border-radius: 40px;
    border-style: solid;
    border-width: 1px;
    padding: 10px 50px;
    font-family: "CiscoSansTT-Regular", sans-serif;
    font-size: $n;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    white-space: normal;
    cursor: pointer;
}

.primaryButton {
    border-color: $cisco-blue;
    background-color: $cisco-blue;
    color: $white !important;
}

.secondaryButton {
    border-color: $cisco-blue;
    background-color: $white;
    color: $cisco-blue;
}

.primaryButton:hover {
    background-color: $dark-blue;
    border-color: $dark-blue;
}

.secondaryButton:hover {
    background-color: rgba($color: $cisco-blue, $alpha: 0.1);
    border-color: $dark-blue;
    color: $dark-blue;
}

.buttonControlDisabled {
    border-color: $grey !important;
    background-color: $grey !important;
    color: $white !important;
    cursor: default !important;
    box-shadow: 0 0 0 0 transparent !important;
}

.buttonControlWrapper:focus,
.buttonControlWrapper:active {
    box-shadow: 0 0 2px 1px $cisco-blue !important;
}
.buttonControlWrapper:not(:focus),
.buttonControlWrapper:not(:active) {
    box-shadow: 0 0 0 0 transparent !important;
}

@media only screen and (max-width: 480px) {
    .buttonControlWrapper {
        min-width: 115px;
    }
}
