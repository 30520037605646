@import "../vars";

.shipment-info-bar {
    position: relative;
    background-color: $white;
    padding: 24px 32px;
    height: 100%;
    width: 100%;
    overflow: auto;
    .main-info {
        width: 100%;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e9e9e9;
        overflow: hidden;
        .row-info {
            width: 100%;
            margin-bottom: 12px;
            word-wrap: break-word;
            table-layout: fixed;
            h4 {
                margin: 0;
            }
            label {
                color: $dark-grey-1;
            }
        }
        .carrier-msg {
            color: $grey;
            margin-bottom: 1rem;
        }
    }
    .actions-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        border-bottom: 1px solid;
        border-color: $light-grey-1;
        .actions-title {
            margin-bottom: 1rem;
        }
        .action-button {
            margin-bottom: 1rem;
            cursor: pointer;
            .icon {
                margin-right: 12px;
            }
        }
    }
    .download-center {
        margin-bottom: 1rem;
        .download-center-title {
            margin-bottom: 1rem;
        }
        .download-center-links {
            display: flex;
            flex-direction: column;
            .download-link {
                margin-bottom: 1rem;
                .InfoTooltipText  {
                    width: 240px;
                }
            }
            .download-icon-link {
                display: flex;
                align-items: center;
                border-radius: 8px;
                background-color: #f7f7f7;
                width: 100%;
                padding: 12px 16px;
                margin-bottom: 1rem;
                cursor: pointer;
                .iconContainer {
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    margin-right: 1rem;
                }
                a {
                    font-family: CiscoSansTT-Bold;
                    color: $cisco-blue;
                    font-size: 14px;
                    letter-spacing: 0;
                    margin: 0;
                }
                .InfoTooltipText  {
                    width: 240px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .shipment-info-bar {
        padding: 16px;
    }
}


@media only screen and (max-width: 62em) {
    .modal-dialog {
        .modal-content {   
            .body {
                .shipment-info-bar {
                    padding: 0;
                }
            }
        }
    }
}