@import '../../vars';

.tabSelector {
    .tabHeadOptions {
        display: flex;
        border-bottom: 1px solid;
        border-color: $light-grey-1;
        margin-bottom: 1rem;
        .tabOption {
            cursor: pointer;
            color: $grey;
            padding: 0 12px;
            margin: 0;
        }
        .tabOptionSelect {
            color: $cisco-blue;
            border-bottom: 2px solid;
            border-color: $cisco-blue;
        }
    }
}