@import "../vars";

.addressBookPageContainer {
    width: 100%;
    min-height: calc(100vh - 124px);
    display: flex;
    .infoContainer {
        width: 100%;
        padding: 32px;
        .flowRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 4px 0 40px 0;
            .flowOptionsContainer {
                display: flex;
                align-items: center;
                div {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .flowOption {
                        margin: 0 8px;
                        white-space: nowrap;
                    }
                }
            }
        }
        .optionContent {
            background-color: $white;

            p {
                height: 38px;
            }
        }

        .action {
            height: 38px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: right;
        }
    }

    .taskBarContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .contactCardBody {
        .contactCardBox {
            min-height: 116px;
            box-sizing: border-box;
            border: 1px solid;
            border-color: $light-grey-1;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            .row {
                div {
                    .infoHead {
                        width: 100%;
                        display: inline-flex !important;
                        justify-content: space-around;
                        align-items: center;
                        // align-items: center;
                        .dropDown {
                            margin: 0 0 8px auto;
                        }
                    }
                }
            }
            .NoInfo {
                height: -webkit-fill-available;
                min-height: 116px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                label {
                    color: $cisco-blue;
                }
            }
        }
    }
    .btnsContainer {
        width: 100%;
        display: flex;
        padding: 0 24px;
        justify-content: space-between;
        align-items: center;
    }
    .grayBackground {
        background-color: $light-grey-1;
    }
}

@media only screen and (max-width: 420px) {
    .addressBookPageContainer {
        flex-direction: column;
        .infoContainer {
            width: 100%;
            padding-bottom: 0;
        }

        .taskBarContainer {
            background-color: $light-grey-1;
            padding: 0.5rem 23px;
        }

        .btnsContainer {
            padding: 21px 0px 21px 0;
            margin-top: 21px;
            background-color: $white;
        }

        .btnSize {
            width: 95%;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .addressBookPageContainer {
        .infoContainer {
            padding: 8px;
        }
    }
}
