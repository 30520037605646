@import "../../vars";

.checkboxContainer {
    .controlError {
        border-color: $red !important;
    }
    position: relative;
    padding-left: 1rem;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        border-radius: 3px;
        border-width: 1px;
        border-style: solid;
        border-color: $dark-grey-2;
        background-color: $white;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border-style: solid;
        border-color: $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .checkboxLabel {
        padding-left: 0.5rem;
        margin: 0;
    }
    .bigLabel {
        padding-top: 3px;
        color: $grey;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
    }

    .inputCheckbox:checked ~ .checkmark {
        background-color: $cisco-blue;
        border-width: 0;
    }
    .inputCheckbox:checked ~ .checkmark:after {
        display: block;
    }
    .inputCheckbox:disabled ~ .checkboxLabel {
        color: $dark-grey-1 !important;
    }
    .bigLabel {
        color: $dark-grey-1;
    }

    .inputCheckbox:checked.inputCheckbox:disabled ~ .checkmark {
        background-color: $grey;
        border-width: 0;
    }

    .inputCheckbox:disabled ~ .checkmark {
        background-color: $light-grey-2;
        border-width: 0;
        cursor: default;
    }

    .checkmark:hover {
        background-color: $light-grey-2;
        border-width: 1px;
        border-style: solid;
        border-color: $cisco-blue;
    }

    .inputCheckbox:not(:disabled) + .checkmark:focus,
    .checkmark:active {
        box-shadow: 0 0 2px 1px $cisco-blue !important;
    }
}
