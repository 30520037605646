@import "../vars";

.main-download {
    padding: 32px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 124px);
    .card-download {
        padding: 24px 24px 12px 24px;
        .head {
            padding-bottom: 16px;
        }
        .body {
            padding: 16px 0 0 0;
        }
    }
    .uploaded-documents-container {
        flex-direction: column;
        .uploaded-documents {
            display: flex;
        }
    }
    .download-button {
        padding-bottom: 12px;
        .button-content {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            padding: 0.8rem 2rem;
            background-color: $light-grey-3;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            span {
                font-family: $cisco-bold;
                font-size: 0.9rem;
                color: $cisco-blue;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main-download {
        padding: 0;
        max-height: calc(100vh - 140px);
        .card-download {
            padding: 16px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .main-download {
        padding: 0;
        margin-top: 16px;
        max-height: 40vh;
    }
}
