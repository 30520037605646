@import '../../vars';

.linkComponent {
    min-height: 14px;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $cisco-blue;
    cursor: pointer;
    // white-space: nowrap;
}

.linkComponentLoading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkComponentDisabled {
    color: $grey !important;
    cursor: default;
    text-decoration: none !important;
}

.linkComponent:not(.linkComponentDisabled):hover {
    color: $dark-blue !important;
    text-decoration: underline;
    text-decoration-color: $dark-blue;
}

.linkComponent:not(.linkComponentDisabled):focus,
.linkComponent:not(.linkComponentDisabled):active {
    color: $cisco-blue !important;
    text-decoration: underline;
    text-decoration-color: $cisco-blue !important;
}


.linkComponentInstruction {
    min-height: 14px;
    font-size: 1.25rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $cisco-blue;
    cursor: pointer;
    // white-space: nowrap;
}
