@import "../vars";

.schedulePickupContainer {
    margin-top: 8px;
    padding-bottom: 32px;
    p {
        margin-bottom: 0.5rem;
    }
    .schedulePickupWrapper {
        margin-top: 8px;
        box-sizing: border-box;
        border: 1px solid rgba(205, 208, 227, 0.3);
        border-radius: 8px;
        background-color: $white;
        width: 100%;
        // height: 65vh;
        overflow-y: auto;
        input {
            text-align: center;
        }
        .actions {
            width: 100% !important;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        table {
            margin: 24px;
            margin-top: 0px;
            width: calc(100% - 48px);
            thead {
                tr {
                    position: sticky;
                    top: 0;
                    background-color: white;
                }
            }
            .pickUpsTitles {
                width: 100%;
                height: 41px;
                border-bottom: 1px solid #e1e2eb;
                .th {
                    cursor: pointer;
                    height: 19px;
                    color: #a6a5a5;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-weight: normal;
                }
            }
            .iconWidth {
                width: 28px;
            }
            .packageSelect {
                width: 100%;
                height: 47px;
                border-bottom: 1px solid #e1e2eb;
                .headPackage {
                    h4 {
                        margin: 0;
                    }
                }
            }
            tbody {
                tr {
                    height: 60px;
                }
            }
            .pickUpsRow {
                width: 100%;
                height: 69px;
                border-bottom: 1px solid #e1e2eb;
                .td {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    white-space: nowrap;
                    .sizeInput {
                        text-align: center;
                        height: 2rem;
                        // width: 4rem;
                        // min-width: 3rem !important;
                        // max-width: calc(10ch + 1rem);
                        width: calc(8ch + 1rem);
                        margin-right: 0.5rem;
                    }
                    .sizeInputWeight {
                        width: calc(8ch + 1.5rem);
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.unitOfMeasurement {
    display: flex;
    .selectUnit {
        margin: 1rem 64px !important;
        width: 182px;
    }
}

@media only screen and (max-width: 1199px) {
    .schedulePickupContainer {
        padding-bottom: 0;
    }
    .schedulePickupWrapper {
        max-height: 45vh;
    }
}

.package-form {
    .inputControlWrapper {
        .inputControl {
            .inputContainer {
                padding: 0 0.5rem;
            }
        }
    }
}


@media only screen and (max-width: 62em) {
    .schedulePickupWrapper {
        max-height: none;
    }
}


@media only screen and (max-width: 480px) {
    .package-form {
        .inputControlWrapper {
            .inputControl {
                .inputContainer {
                    max-width: 25vh;
                }
            }
        }
    }
}