.contactCardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-grey-2;
  h4 {
    margin: 0;
  }
}
.contactCardBody {
  .contactCardBox {
    min-height: 134px;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid;
    border-color: $light-grey-1;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    .row {
      div {
        .infoHead {
          width: 100%;
          display: inline-flex !important;
          justify-content: space-around;
          align-items: center;
          // align-items: center;
          .dropDown {
            margin: 0 0 6px auto;
          }
        }
      }
    }
    .NoInfo {
      height: 100%;
      height: fill-available;
      height: -webkit-fill-available;
      min-height: 116px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        color: $cisco-blue;
      }
    }
    .truncateText {
      //width: 215px;  //285 gstin issue
      display: block;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .titleInfo{
      font-family: $cisco-bold;
      color: $dark-grey-2;
    }
    .lblInfo{
      color: $dark-grey-2;
    }
    .borderBottom{
      color: $light-grey-2;
      margin: 0;
      width: 80%;
    }
  }
}
.full-height {
  height: 100%;
}
