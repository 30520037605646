@import "../vars";

.actionCard {
    height: 100%;
    max-width: 100%;
    padding: 24px;
    border-radius: 8px;
    border: solid 1px rgba(205, 208, 227, 0.3);
    background-color: $white;
    display: flex;
    .header {
        display: flex;
        justify-content: center;
        padding: 0 24px 0 0;
    }
    .actionCardBody {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        .inputControlWrapper {
            padding: 0;
        }
        .comments {
            margin-top: 8px;
            color: $grey;
            font-size: 12px;
            letter-spacing: 0;
        }
        .card-button {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            justify-content: flex-end;
        }
        .card-checkbox {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            justify-content: flex-end;
            padding-right: 4rem;
            .infoCircle {
                position: absolute;
                right: 4.5rem;
            }
        }
        
        .warning {
            margin-top: 24px;
            display: flex;
            label {
                margin: 0;
                padding-left: 0.5rem;
            }
            .link {
                color: $cisco-blue;
                cursor: pointer;
            }
            .link:hover {
                color: $dark-blue;
                text-decoration: underline;
                text-decoration-color: $dark-blue;
            }
            .link:active {
                color: $cisco-blue;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .actionCard {
        flex-direction: column;
        .header {
            border-bottom: 1px solid $light-grey-1;
            padding: 0 0 8px 0;
            margin-bottom: 16px;
            align-items: center;
            justify-content: start;
        }
        .actionCardBody {
            .card-head {
                min-height: auto;
                max-height: none;
            }
        }
     
    }
}

@media only screen and (max-width: 1199px) {
    .actionCard {
        padding: 16px;
    }
}