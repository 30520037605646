@import '../../vars';

.selectControlWrapper {
    .selectControl {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        margin-top: 1rem;
        position: relative;
        .header-select {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        label.clear-btn {
            color: $cisco-blue;
            cursor: pointer;
        }
        &.no-space {
            margin: 0px;
        }
        &.inside-table {
            width: 170px;
        }
        .inputLabel {
            color: $dark-grey-2;
            padding-left: 1rem;
            max-width: 85%;
        }
        .selectContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid;
            border-color: $light-grey-1;
            height: 2rem;
            padding: 0 0.5rem;
            &.multiple{
                padding-left: 0px;
                padding-right: 4px;
                position: relative;
            }
            .clearContainer{
                width: 100%;
                padding-left: 0px;
                padding-right: 4px;
                position: relative;
                input {
                    padding-left: .5rem;
                }
            }
            .dot {

                height: 10px;
                width: 10px;
                border-radius: 50%;
                float: left;
                margin: 6px;
              }
              .overflow{
                  overflow: hidden;
                }
            .text {
                height: 1.5rem;
                width: 100%;
                font-size: 0.875rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.13px;
                color: $dark-grey-1;
                border: none;
                border-color: transparent;
                border-color: $light-grey-1;
                background-color: transparent;
                .value-tag {
                    background-color: $light-grey-1;
                    padding: 5px;
                    margin: 3px 2px;
                    font-size: .6rem;
                    span {
                        cursor: pointer;
                    }
                }
            }
            .text-table{
                font-size: .75rem;
            }
            .text:focus {
                border: none;
            }
            .text:active {
                border: none;
                border-color: transparent;
            }
            .text::placeholder {
                color: $light-grey-1;
            }
            .text:-ms-input-placeholder {
                color: $light-grey-1;
            }
            .text::-ms-input-placeholder {
                color: $light-grey-1;
            }
        }
        .selectContainerActive {
            border-color: $cisco-blue;
            border-width: 2px;
        }
        .selectContainer:hover {
            border-color: $dark-blue !important;
            border-width: 2px !important;
        }
        .selectOptions {
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            max-height: 8rem;
            overflow: auto;
            position: absolute;
            top: 100%; 
            left: 0px;
            width: 100%;
            background-color: $white;
            z-index: 2;
            .selectOption {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                height: 2.5rem;
                width: 100%;
                color: $dark-grey-1 !important;
                font-size: 0.875rem;
                letter-spacing: 0.13px;
                border-bottom: 1px solid;
                border-color: $light-grey-1;
                &.single {
                    cursor: pointer;
                }
                .checkboxContainer {
                    overflow: hidden;
                    margin-bottom: 0;
                }
                .dot {

                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    float: left;
                    margin: 6px;
                  }
            }
            .selectOption-table{
                font-size: .75rem;
                height: auto;
            }
            .selectOptionActive {
                background-color: $cisco-blue;
                color: $white !important;
                &:hover {
                    background-color: $cisco-blue !important;
                    color: $white !important;
                }
            }
            .selectOption:hover {
                background-color: $cisco-blue-opacity;
            }
            .single:active {
                background-color: $cisco-blue;
            }
        }
    }

    .selectControlError {
        border-bottom-width: 2px !important;
        border-bottom-color: $red !important;
        margin-bottom: 0 !important;
    }
    .errorMessage {
        margin: 4px 0 0 0;
        padding-left: 1rem;
        height: 1rem;
        font-size: 0.75rem;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: $red !important;
    }
}

.selectControlDisabled {
    opacity: 0.5;
    .selectControl {
        .selectContainer:hover {
            border-color: $light-grey-1 !important;
            border-width: 1px !important;
        }
    }
    .selectable {
        cursor: default;
    }
}

.selectable {
    cursor: pointer;
}