@import '../vars';

.createPackagesWrapper {
    .createPackagesTitleHead {
        .head-actions {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 12px;
        }
    }
    .createPackagesContainer {
        box-sizing: border-box;
        border: 1px solid;
        border-color: $light-grey-1;
        border-radius: 8px;
        background-color: $white;
        // height: 73vh;
        overflow-y: auto;
        overflow-x: hidden;
        table {
            margin: 24px;
            width: calc(100% - 48px);
            thead {
                tr {
                    position: sticky;
                    top: 0;
                    background-color: $white;
                    z-index: 10;
                    width: 100%;
                    border-bottom: 1px solid;
                    border-color: $light-grey-1;
                }
            }
            .packagesTitles {
                width: 100%;
                height: 41px;
                border-bottom: 2px solid;
                border-color: $light-grey-1;
                th {
                    cursor: default;                    
                    font-family: $cisco-light;
                    font-weight: initial;
                    flex-wrap: nowrap;
                    margin: 0;
                    color: $grey;
                    font-size: 12px;
                    min-height: 0.75rem;
                }
            }
            .packagesRow + .packageSelect {
                border-top: 1px solid;
                border-color: $light-grey-1;
            }
            .packageSelect {
                height: 55px;
                td {
                    border-bottom: 1px solid;
                    border-color: $light-grey-1;
                }
                .headPackage {
                    width: 180px;
                    border: none;
                    h4 {
                        color: $dark-grey-2;
                        margin: 0;
                    }
                }
                .showLines {
                    height: 55px;
                    display: flex;
                    align-items: center;
                    .showLinesIcon {
                        cursor: pointer;
                        margin-bottom: -2px;
                    }
                    h4 {
                        padding-left: 8px;
                        margin: 0;
                    }
                }
                p {
                    margin: 0;
                }
            }
            .packagesRow {
                width: 100%;
                height: 55px;
                .rma-empty {
                    min-width: 149px;
                    max-width: 150px;
                }
                .line {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    p {
                        color: $dark-grey-1;
                        margin: 0;
                    }
                }
                td {
                    text-align: center;
                    border-bottom: 1px solid;
                    border-color: $light-grey-1;
                }
                &.error {
                    td {
                        border-color: $red;
                    }
                }
                .noBorder {
                    border: none;
                }
            }
        }
        .checkError {
            .checkmark {
                border-color: $red;
            }
        }
    }
}
