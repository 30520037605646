@import "../../vars";

table {
    width: 100%;
    .table-headers {
        width: 100%;
        border-bottom: 1px solid $light-grey-1;
        .table-header {
            height: 56px;
            padding: 0 8px;
            .title {
                cursor: pointer;
                display: flex;
                align-items: end;
                flex-wrap: nowrap;
                margin: 0;
                font-family: "CiscoSansTT-Regular", sans-serif;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $grey;
                font-size: 10px;
                // max-width: 92px;
                min-height: 0.75rem;
                .arrowIcon {
                    margin-bottom: 2px;
                }
            }
        }
    }
    .table-row {
        height: 56px;
        width: 100%;
        border-bottom: 1px solid $light-grey-1;
        td {
            padding: 0 8px;
            div.td {
                font-family: $cisco-regular;
                color: $dark-grey-1;
                font-size: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 0;
                cursor: default;
                &.generic {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                }
                &.actionsDropDown {
                    display: flex;
                    justify-content: flex-end;
                    overflow: 0;
                }
                &.infoContainer {
                    display: flex;
                    justify-content: flex-end;
                    overflow: 0;
                    .infoCircle {
                        margin-left: 1rem;
                    }
                }
                &.inputContainer {
                    .inputTable {
                        height: 2rem;
                        margin: 0;
                        max-width: 260px;
                    }
                }
                &.boldType {
                    font-family: $cisco-bold;
                }
            }
            .colorRed {
                color: $red !important;
            }
            .actionsDropDown {
                display: flex;
                justify-content: flex-end;
                overflow: 0;
            }
            .showLinesIcon {
                cursor: pointer;
            }
        }
    }
    .noBorders {
        border: none;
        height: 19px;
        .table-header {
            height: 19px;
            .title {
                font-size: 14px;
            }
        }
        .td {
            max-width: 120px;
            font-size: 14px;
        }
    }
     .imageTable{
        width: 30px;
        margin:  auto 20px;
    }
}
