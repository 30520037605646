@import "../vars";

.shipmentDetailContainer {
    width: 100%;
    max-height: calc(100vh - 124px);
    min-height: calc(100vh - 124px);
    padding: 24px 32px;
    .colorSuccess {
        color: green;
    }
    .shipmentDetailTable {
        min-height: 110px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid;
        border-color: $light-grey-1;
        border-radius: 8px;
        background-color: $white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.5rem;
        padding-bottom: 0;
        margin-bottom: 1.5rem;
        .shipProp {
            overflow: hidden;
            min-width: 90px;
            padding-bottom: 1.5rem;
            label {
                max-width: 106px;
            }
            p {
                margin-bottom: 0;
                width: min-content;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .double {
                min-height: 42px;
                width: auto;
                max-width: 154px;
            }
            .update {
                white-space: nowrap;
            }
        }
    }
    .carrierContents {
        .carrierTable {
            padding: 32px 24px;
            box-sizing: border-box;
            border: 1px solid;
            border-color: $light-grey-1;
            border-radius: 8px;
            background-color: $white;
            overflow: hidden;
            .timeOption {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-right: 1.5rem;
                margin-bottom: 1rem;
            }
            .historyOption {
                width: 60%;
                display: flex;
                .icon {
                    width: 16px;
                    height: 16px;
                    margin-left: -8px;
                    margin-right: 8px;
                }
            }
            .carrierMessage {
                word-wrap: break-word;
                table-layout: fixed;
                p {
                    color: $grey;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
            }
            .dot-line {
                border-left: 1px dashed;
                border-color: $cisco-blue;
                .icon {
                    margin-left: -9px;
                }
            }
        }
        .additionalInstr {
            padding: 24px;
            box-sizing: border-box;
            border: 1px solid;
            border-color: $light-grey-1;
            border-radius: 8px;
            background-color: $white;
            overflow: hidden;
        }
    }
    .pickupAddressCarrier {
        .pickupAddressTable {
            width: 100%;
            .tableContents {
                box-sizing: border-box;
                border: 1px solid;
                border-color: $light-grey-1;
                border-radius: 2px;
                background-color: $white;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: flex-start;
                padding: 18px 0;
                div {
                    display: flex;
                    flex-direction: column;
                    label,
                    h3 {
                        padding: 0 24px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .shipmentDetailContainer {
        padding: 8px;
        max-height: none;
        min-height: auto;
        .shipmentDetailTable {
            padding: 16px;
        }
        .carrierContents {
            .carrierTable {
                padding: 16px;
            }
        }
    }
}
