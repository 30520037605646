@import "../vars";

.main-alert {
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    z-index: 10;
    .row-alert {
        display: none;
        justify-content: space-between;
        align-items: center;
        min-height: 42px;
        width: 100%;
        padding: 12px 24px 11px;
        background-color: $blue;
        p {
            color: $white;
            margin: 0;
            white-space: pre;
            text-wrap: auto;
            .link {
                color: $dark-grey-1;
            }
            .linkComponent {
                color: $white;
                font-weight: bold;
                text-transform: none;
                font-size: inherit;
            }
            .linkComponent:hover {
                color: $white !important;
                font-weight: bold;
                text-decoration-color: $white !important;
            }
        }
    }
    .d-alert {
        display: flex;
    }

    .row-alert.green {
        background-color: $green;
    }
    .row-alert.red {
        background-color: $red;
    }
    .row-alert.yellow {
        background-color: $orange;
    }
    .row-alert.orange {
        background-color: $orangereal;
    }
    .row-alert.top {
        margin-top: 1px;
    }
}

@media only screen and (max-width: 480px) {
    .main-alert {
        width: calc(100% + 80px);
        right: 0;
        left: auto;
    }
    .modal-content {
        .main-alert {
            width: 100%;
            top: auto;
            left: auto;
            right: auto;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main-alert {
        .row-alert {
            padding: 16px;
        }
    }
}
