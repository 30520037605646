@import "../vars";

.main-shipments {
    width: 100%;
    padding: 32px 24px;
    .head-lg {
        h1 {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .main-shipments {
        padding: 8px 0 0;
    }
}
