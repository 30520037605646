.inputControlWrapper {
    padding: 16px 0;
    .inputControl {
        display: flex;
        flex-direction: column;
        height: 3.5rem;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: $light-grey-1;
        &.readonly {
            border-bottom-width: 1px !important;
            border-bottom-color: $light-grey-2 !important;
        }
        label {
            color: $dark-grey-2;
            padding: 0 1rem;
        }
        .clear-btn {
            cursor: pointer;
            color: $cisco-blue;
            margin-right: -1rem;
        }
        .inputContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            .text {
                height: 1.5rem;
                width: 100%;
                font-size: 0.875rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.13px;
                color: $dark-grey-1;
                border: none;
                border-color: transparent;
                border-color: $light-grey-1;
                background-color: transparent;
            }
            .text:focus {
                border: none;
                border-color: transparent;
            }
            .text:active {
                border: none;
                border-color: transparent;
            }
            .text::placeholder {
                color: $light-grey-1;
            }
            .text:-ms-input-placeholder {
                color: $light-grey-1;
            }
            .text::-ms-input-placeholder {
                color: $light-grey-1;
            }
            input[type="number"] {
                -moz-appearance: textfield;
            }
            input[type="number"]::-webkit-inner-spin-button, 
            input[type="number"]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }
        }
    }
    .no-border{
        border-bottom-style:none;
    }
    .inputControl:hover {
        border-bottom-width: 2px;
        border-bottom-color: $dark-blue;
    }
    .inputControl:active,
    .inputControlWriting {
        border-bottom-width: 3px;
        border-bottom-color: $cisco-blue;
    }
    .inputControlText {
        border-bottom-width: 2px;
    }
    .inputControlError {
        border-bottom-width: 2px;
        border-bottom-color: $red;
        // margin-bottom: 0 !important;
    }
    .errorMessage {
        margin: 4px 0 0 0;
        padding-left: 1rem;
        height: 1rem;
        font-size: 0.75rem;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: $red !important;
    }
}

@media only screen and (max-width: 1199px) {
    .inputControlWrapper {
        .inputControl {
            .inputContainer {
                padding: 0 0.5rem;
            }
        }
    }
}