@import '../vars';

.created-shipments {
    display: flex;
    justify-content: space-between;
}

.dashboardCardRMA {
    .actionCard {
        .actionCardBody {
            margin-left: 24px;
            h3 {
                color: $grey;
                margin-bottom: 1.5rem;
            }
            .infoTag {
                flex: 0 0 20%;
                max-width: 20%;
                padding: 0 8px 1.5rem;
                h4, p { margin: 0; }
                color: $dark-grey-1;                    
            }
            .buttonContainer {
                margin-left: auto;
                .buttonControlWrapper {
                    margin: 0;
                    margin-right: -1rem;
                }
            }
            .carrierMessage {
                color: $grey;
            }
        }
    }
}