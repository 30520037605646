.main-preprinted {
    padding: 32px;
    .preprinted-header {
        padding-bottom: 16px;
    }
}

.preprinted-sm {
    button {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .main-preprinted {
        padding: 0;
        .preprinted-header {
            button {
                width: 100%;
            }
        }
    }
}