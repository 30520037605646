@import "../vars";

.successReturnContainer {
    box-sizing: border-box;
    border: 1px solid rgba(205, 208, 227, 0.3);
    border-radius: 8px;
    background-color: $white;
    padding: 24px;
    .successCardBody {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .successMessage {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 32px;
            .maxWidth {
                max-width: 80%;
            }
            .exclamationRectangle {
                background-color: $orange;
                padding: 13px 24px;
                border-radius: 4px;
                margin-bottom: 1rem;
                p,
                span {
                    text-align: center;
                    margin: 0;
                    color: $white;
                    .InfoTooltipContainer {
                        width: min-content;
                        margin-left: 0.5rem;
                        .InfoTooltipText {
                            min-width: 200px;
                        }
                    }
                    a {
                        color: $white;
                        text-decoration: underline;
                        text-decoration-color: $white;
                        margin-left: 0.25rem;
                    }
                }
            }
            .exclamationCircle {
                background-color: $red;
                padding: 13px 24px;
                border-radius: 4px;
                margin-bottom: 1rem;
                p,
                span {
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    margin: 0;
                    color: $white;
                    .InfoTooltipContainer {
                        width: min-content;
                        margin-left: 0.5rem;
                        .InfoTooltipText {
                            min-width: 200px;
                        }
                    }
                    a {
                        color: $white;
                        text-decoration: underline;
                        text-decoration-color: $white;
                        margin-left: 0.25rem;
                    }
                }
            }
            p {
                text-align: center;
            }
        }
        .successCardInfo {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 1rem;
            border-top: 1px solid $light-grey-1;
            .infoRequest {
                display: flex;
                .infoBlock {
                    display: flex;
                    flex-direction: column;
                    margin-right: 24px;
                    p {
                        margin: 0;
                    }
                    strong {
                        color: $dark-grey-1;
                    }
                }
                .link {
                    color: $cisco-blue;
                    cursor: pointer;
                }
                .link:hover {
                    color: $dark-blue;
                    text-decoration: underline;
                    text-decoration-color: $dark-blue;
                }
                .link:active {
                    color: $cisco-blue;
                }
            }
        }
        table {
            width: 100%;
            .packagesTitles {
                width: 100%;
                border-bottom: 1px solid $light-grey-1;
                .packagesTH {
                    height: 56px;
                    padding: 0 8px;
                    .packagesNoWrap {
                        white-space: nowrap;
                    }
                    .packagesTitle {
                        font-family: $cisco-light;
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        margin: 0;
                        color: $grey;
                        font-size: 12px;
                        max-width: 92px;
                        min-height: 0.75rem;
                        cursor: pointer;
                    }
                }
            }
            .packagesRow {
                height: 56px;
                width: 100%;
                border-bottom: 1px solid $light-grey-1;
                td {
                    padding: 0 8px;
                    div.td {
                        font-family: $cisco-regular;
                        color: $dark-grey-1;
                        font-size: 12px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 0;
                        cursor: default;
                    }
                    div.td.boldType {
                        font-family: $cisco-bold;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .successReturnContainer {
        padding: 16px;
    }
}
