@import "../vars";

.requestRetunBar {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.17);
    background-color: $white;
    .info-content{
        width: 80%;
        height: 100%;
        .infoContentBox {
            display: flex;
            flex-direction: row;
            width: 100%;
            border-bottom: .5px solid;
            padding:30px 10px 10px 10px;
            border-color: $grey;
            justify-content: space-between;
            align-items: flex-start;
            h3 {
                margin-bottom: 0;
            }
            p{
                margin: 0;
            }
        }
        .header-gray{
            color: $grey;
        }
        .products-list{
            display: flex;
            flex-direction: row;
            width: 100%;
            padding:10px;
            border-bottom: .5px solid;
            border-color: $grey;
            justify-content: space-between;
            align-items: flex-start;
            p{
                margin: 0;
                max-width: 80%;
            }
        } 
        .no-border{
            border:none;
            padding-bottom: 0;
        }
    }
    .footer-info{
        width: 80%;
        margin-bottom: 15px;

    }
}
.requestReturnText{
    display: none;
}
@media only screen and (max-width: 1199px) {
    .returnTaskBarContainer {
        border: 1px solid rgba(205, 208, 227, 0.3);
        border-radius: 8px;
        .btnSize {
            width: 100%;
        }
        .setPickUpLocation,
        .selectedParts,
        .createPackage,
        .schedulePickUp,
        .summary {
            background-color: $white;
            padding: 16px;
        }
        .success {
            background-color: $white;
            padding: 1.5rem;
        }
    }
    .setPickUpLocation {
        box-sizing: border-box;
        background-color: $white;
        padding: 1.5rem;
    }
}
.productInfo{
    display: flex;
}
.modalText{
    height: 250px; 
    overflow-x: auto;
}

.mobileButtons{
    display: none;
}
.requestReturnButtons{
    display: flex;
}
.infoDropdown{
    display: none;
}
.reviewInfo{
    width:100%;
    font-size: 12px;
}
.mobileProduct{
    display:none
}
.tableHeaders{
    display: revert;
}
.qty{
    border-bottom: 1px solid #DFDFDF;
}
.spacer{
    display: none;
}
.backButton-reason{
    position: absolute;
    bottom: 10px
}

@media only screen and (max-width: 992px) {
    .returnTaskBarContainer {
        height: auto;
        .selectControlWrapper {
            .selectControl {
                margin-bottom: 1rem;
                .selectOptions {
                    position: relative !important;
                }
            }
        }
    }
    .productInfo{
        display: none;
    }
    .modalText{
        height: auto; 
    }
    .mobileButtons{
        display: block;
    }
    .requestReturnButtons{
        display: none;
    }
    .infoDropdown{
        display: initial;
    }
    .mobileProduct{
        display: block;
    }
    .product{
        display:none
    }
    .units{
        margin-right: 5rem;
    }
    .tableHeaders{
        display: none;
    }
    .selectContainer{
        width: auto;
    }
    .requestReturnText{
        display: block;
        padding-left: 38vw;
        color: $dark-blue;
        font-weight: bold;
    }
    .productRow{
        background-color: white;
    }
    .spacer{
        height: .3rem;
        display: block;
    }
    .backButton-reason{
        position: static;
        max-width: fit-content;
    }
}
