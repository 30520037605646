@import "../vars";

.viewPackagesTable {
    box-sizing: border-box;
    border: 1px solid;
    border-color: $light-grey-1;
    border-radius: 8px;
    background-color: $white;
    padding: 0 24px 16px;
    table {
        width: 100%;
        tr {
            border-bottom: 1px solid #e1e2eb;
        }
        .packagesTitles {
            width: 100%;
            height: 56px;
            th {
                cursor: pointer;
                height: 19px;
                color: #a6a5a5;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                font-weight: normal;
            }
            .pflex {
                display: inline;
            }
        }
        .packagesRow {
            width: 100%;
            td {
                text-align: center;
                padding-right: 16px;
                height: 54px;
            }
        }
    }
}

.modal-view-packages {
    padding-bottom: 16px;
    .headers-package {
        p {
            font-family: $cisco-bold;
        }
    }
    .descriptions-package {
        th {
            font-size: 10px;
            color: $grey;
        }
    }
    .row-packages {
        p {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .viewPackagesTable{
        padding: 0 16px 16px
    }
    .modal-view-packages {
        .row-mini-package {
            border-top: 1px solid $gray-opacity;
            p {
                font-size: 12px;
            }
            .selectControlWrapper {
                .selectControl {
                    .selectContainer {
                        height: auto;
                    }
                }
            }
        }
    }
}
