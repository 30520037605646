@import "../../vars";

.inputCalendarWrapper {
    .inputCalendar {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 3.5rem;
        border-bottom: 1px solid;
        border-color: $light-grey-1;
        margin-bottom: 2rem;
        margin-top: 1rem;
        .label {
            padding-left: 1rem;
            white-space: nowrap;
        }
        .label-form{
            color:#686767
        }
        .clear-btn {
            cursor: pointer;
            color: $cisco-blue;
        }
        &.readonly {
            border-bottom: 1px solid $light-grey-2 !important;
        }
        &.calendarControlError {
            border-bottom:2px solid $red !important;
            margin-bottom: 0 !important;
        }
        .inputCalendarContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 0.5rem;
            .text {
                height: 1.5rem;
                width: 100%;
                font-size: 0.875rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.13px;
                color: $dark-grey-1;
                border: none;
                background-color: transparent;
                border-color: $light-grey-1;
                overflow: hidden;
            }
            .text::placeholder {
                color: $light-grey-1;
            }
            .text:-ms-input-placeholder {
                color: $light-grey-1;
            }
            .text::-ms-input-placeholder {
                color: $light-grey-1;
            }
            .iconCalendar {
                cursor: pointer;
            }
            .iconCalendar:focus {
                color: $dark-blue;
            }
        }

        .reactCalendarContainer {
            position: absolute;
            z-index: 10;
        }
        .react-calendar {
            width: 285px;
            max-width: 285px;
            padding: 24px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            .react-calendar__navigation {
                height: 20px;
                position: relative;
                align-items: center;
                justify-content: space-around;
                margin: 0 auto;
                .react-calendar__navigation__label {
                    position: absolute;
                    left: 3%;
                    text-transform: capitalize;
                    padding: 0;
                    .calendar__navigation__label__labelText,
                    .react-calendar__navigation__label__labelText--from {
                        height: 20px;
                        color: $grey;
                        font-size: 15px;
                        letter-spacing: 0.59px;
                        line-height: 20px;
                        font-weight: 500;
                    }
                }
                .react-calendar__navigation__arrow {
                    height: 22px !important;
                    width: 22px !important;
                    max-width: 22px !important;
                    min-width: 22px !important;
                    background-color: $white;
                }
                .react-calendar__navigation__prev-button {
                    margin-left: 80%;
                }
                .react-calendar__navigation__prev2-button,
                .react-calendar__navigation__next2-button {
                    display: none;
                }
            }
            .react-calendar__viewContainer {
                margin: 1rem auto 0 auto;
                width: 236px;
                .react-calendar__month-view {
                    .react-calendar__month-view__weekdays {
                        margin-bottom: 10px;
                        .react-calendar__month-view__weekdays__weekday {
                            padding: .5rem 0;
                            abbr {
                                display: block;
                                height: 20px;
                                font-size: 15.65px;
                                letter-spacing: 0;
                                line-height: 19.01px;
                                text-align: center;
                                text-decoration: none;
                                font-weight: 500;
                            }
                            abbr::first-letter {
                                color: $dark-grey-1;
                            }
                        }
                    }
                    .react-calendar__tile {
                        background: none !important;
                        border-radius: 50%;
                        height: 33px;
                        padding: 7px 0;
                        abbr {
                            height: 20px;
                            color: $grey;
                            font-size: 14.54px;
                            letter-spacing: 0;
                            line-height: 20px;
                            text-align: center;
                        }
                    }
                    .react-calendar__tile:hover {
                        background-color: rgba($color: $cisco-blue, $alpha: 0.2) !important;
                    }
                    .react-calendar__tile--now {
                        border-width: 1px;
                        border-style: solid;
                        border-color: $cisco-blue;
                    }
                    .react-calendar__tile--active {
                        background: $cisco-blue !important;
                        abbr {
                            color: $white;
                        }
                    }
                    .react-calendar__tile:disabled abbr {
                        color: $light-grey-1;
                    }
                }
                .react-calendar__year-view,
                .react-calendar__decade-view,
                .react-calendar__century-view {
                    .react-calendar__tile {
                        padding: 1em 0.5em;
                        color: $grey;
                        abbr {
                            color: $grey;
                        }
                    }
                    .react-calendar__tile--hasActive,
                    .react-calendar__tile--hasActive:enabled {
                        background: rgb(0, 80, 115);
                        color: $white;
                        abbr {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    .inputCalendar:hover {
        border-bottom-color: $dark-blue;
    }
    .inputCalendarWriting {
        border-bottom-color: $cisco-blue;
        border-bottom-width: 2px;
    }
    .height-auto{
        height: auto;
    }
}
