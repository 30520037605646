@import "../vars";

.returnTaskBarContainer {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.17);
    background-color: $white;
    .infoContentBox {
        box-sizing: border-box;
        padding: 16px;
        width: 100%;
        border: 1px solid;
        border-color: $light-grey-1;
        border-radius: 2px;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 24px;
        h3 {
            margin-bottom: 0;
        }
    }
    .bondedAreaContainer {
        .zoneYes {
            display: flex; 
            justify-content: space-between;
            position: relative;
        }
        .zoneMessage {
            .zoneInstructions {
                border-color: $light-grey-1;
                border-style: solid;
                border-width: 1px;
                textarea {
                    height: 78px;
                    border: none;
                    resize: none;
                }
                .zoneNARadio {
                    margin: 0 0 8px 8px;
                    label {
                        color: $dark-grey-1;
                        padding-top: 2px;
                        font-size: 12px;
                    }
                }
            }
            .zoneInstructionsError {
                border-color: $red;
                border-width: 2px;
            }
            .errorMessage {
                font-weight: bold;
                text-align: center;
            }
        }
    }
    .infoTooltip {
        position: absolute;
        top: 4px;
        right: 0;
        .infoText {
            min-width: 280px;
            font-size: 12px;
        }
    }
    .infoContainer {
        width: 100%;
        padding-bottom: 1rem;
        .infoHead {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e9e9e9;
            padding-bottom: 1rem;
            label {
                margin-right: 15px;
            }
            h3 {
                margin: 0;
            }
        }
        .wrapText {
            word-wrap: break-word;
        }
        .infoBody {
            margin-top: 15px;
            .infoSummary {
                .addtional {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            thead {
                tr {
                    position: sticky;
                    top: 0;
                    background-color: white;
                }
            }
            &.parts-list {
                height: 100%;
            }
            .checksContainer {
                padding: 13px;
            }
            p.line {
                display: flex;
                justify-content: space-between;
                margin: 0;
            }
            .packCreated {
                width: 100%;
                color: $grey;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                th {
                    font-weight: normal;
                }
            }
            tr {
                height: 21px;
            }
            .iconPosition {
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 25px;
            }
            .clear-btn {
                color: $cisco-blue;
                cursor: pointer;
            }
            .disabled-btn {
                color: $light-grey-1;
            }
            .inputControlWrapper {
                padding-top: 0;
            }
        }
        .displayCheckBox {
            display: inline-block;
        }
        .timeSelector {
            width: 100%;
            .timeChecks {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
        }
        .infoContainer {
            padding: 0;
        }
        .widthBtn {
            width: 70%;
            margin: 0 auto;
        }
    }
    .setPickUpLocation,
    .selectedParts,
    .createPackage,
    .schedulePickUp,
    .summary {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 24px;
        .selectPackaging {
            display: flex;
            flex-direction: column;
            .selectPackagingButton {
                margin: 0;
                width: 100%;
                margin-bottom: 0.5rem;
                font-size: 13px;
            }
            .infoCircle {
                width: min-content;
                margin-left: 0.5rem;
            }
        }
        .errorButtons {
            border: 1px solid;
            border-radius: 3px;
            border-color: $red;
        }
        .ml-40 {
            margin-left: 40%;
        }
    }
    .mt-25 {
        margin-top: 2rem;
    }
    .success {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 32px 24px;
        .iconCardContainer {
            display: flex;
            align-items: center;
            border-radius: 8px;
            background-color: #f7f7f7;
            width: 100%;
            padding: 12px 28px;
            margin-bottom: 1.5rem;
            cursor: pointer;
            .iconContainer {
                width: 32px;
                display: flex;
                justify-content: center;
            }
            p {
                color: $cisco-blue;
                font-weight: bold;
                margin: 0;
            }
        }
    }
    .btnsContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        padding-top: 8px;
        background-color: $white;
        min-height: min-content;
        .btnSize {
            margin: 0;
            padding: 0;
            width: calc(50% - 12px);
        }
        .actButton {
            width: 100%;
        }
    }
    .btnContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        background-color: $white;
        .widthBtn {
            width: 100%;
        }
        .finishedMessage {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            p {
                color: $green;
                margin-bottom: 0;
            }
        }
    }
    .returnHome {
        padding: 0 56px;
        width: 100%;
        .buttonControl {
            width: 100%;
        }
    }
    .cancel-save-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px 24px 24px;
        hr {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0.75rem;
        }
        .cancel-save-btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .returnTaskBarContainer {
        border: 1px solid rgba(205, 208, 227, 0.3);
        border-radius: 8px;
        .btnSize {
            width: 100%;
        }
        .setPickUpLocation,
        .selectedParts,
        .createPackage,
        .schedulePickUp,
        .summary {
            background-color: $white;
            padding: 16px;
        }
        .success {
            background-color: $white;
            padding: 1.5rem;
        }
    }
    .setPickUpLocation {
        box-sizing: border-box;
        background-color: $white;
        padding: 1.5rem;
    }
}


@media only screen and (max-width: 62em) {
    .returnTaskBarContainer {
        height: auto;
        .selectControlWrapper {
            .selectControl {
                margin-bottom: 1rem;
                .selectOptions {
                    position: relative !important;
                }
            }
        }
    }
}
