@import "../../vars";

.InfoTooltipContainer {
    position: relative;
    .InfoTooltipText {
        font-size: 14px;
        background-color: $dark-grey-1;
        color: $white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 8px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        margin-left: -8px;
        opacity: 0;
        transition: opacity 0.3s;
        transform: translateX(-90%);
        visibility: hidden;
        border-bottom-right-radius: 0px;
    }
    .InfoTooltipText::after {
        content: "";
        width: 10px;
        position: absolute;
        top: 100%;
        left: 95%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent;
        border-top-color: $dark-grey-2;
    }
}

.middle-tooltip {
    .InfoTooltipText {
        width: max-content;
        transform: translateX(-50%);
        margin: 0;
        border-radius: 6px;
    }
    .InfoTooltipText::after {
        left: 45%;
        margin: 0;
    }
}

.big-tooltip {
    min-width: 20rem;
}

.InfoTooltipContainer:hover .InfoTooltipText {
    visibility: visible;
    opacity: 1;
}
