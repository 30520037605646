$dark-blue: #005073;
$cisco-blue: #099AD6;
$white: #ffffff;
$dark-grey-1: #58585b;
$dark-grey-2: #686767;
$grey: #9194a7;
$light-grey-1: #dfdfdf;
$light-grey-2: #f2f2f2;
$light-grey-3: #f6f7fa;
$blue: #0e84c9;
$green: #40c90e;
$orange: #ffb600;
$red: #ff4646;
$orangereal: #ff8c00;


$gray-opacity: rgba(145, 148, 167, 0.1);
$cisco-blue-opacity: rgba(9, 154, 214, 0.1);

$xs: 8px;
$n: 16px;
$md: 24px;
$lg: 32px;
$xl: 40px;
$xxl: 48px;

$cisco-light: CiscoSansTT-Light;
$cisco-regular: CiscoSansTT-Regular;
$cisco-bold: CiscoSansTT-Bold;
