@import "./vars";

.shipmentsListCard {
    padding: 16px;
    padding-top: 22px;
    border-radius: 8px;
    border: solid 1px rgba(205, 208, 227, 0.3);
    background-color: $white;
    width: 100%;
    min-height: 183px;
    table {
        width: 100%;
        table-layout: fixed;
        .shipmentsTitles {
            width: 100%;
            border-bottom: 1px solid #e1e2eb;
            .wider-col{
                width: 18%;
            }
            .wider-less-col{width: 5%;}
            .shipmentsTH {
                cursor: pointer;
                min-height: 36px;
                padding: 0 8px 12px;
                .shipmentsTitle {
                    font-family: $cisco-light;
                    font-weight: initial;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin: 0;
                    color: #a6a5a5;
                    font-size: 12px;
                    min-height: 0.75rem;
                }
                
                .text-right {
                    justify-content: flex-end;
                }
            }
            .min-width {
                width: 22px;
            }
            .act-width {
                width: 58px;
            }
        }
        .shipmentRow {
            cursor: default;
            height: 56px;
            width: 100%;
            border-bottom: 1px solid #e1e2eb;
            td {
               
                .td {
                    font-family: $cisco-regular;
                    color: $dark-grey-1;
                    font-size: 12px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin: 0;
                    cursor: default;
                    align-items: baseline;
                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                padding: 0 8px;
                .height-auto{
                    height: auto;
                }
                .td-calendar-table{
                    font-family: $cisco-regular;
                    color: $dark-grey-1;
                    font-size: 12px;
                    // overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin: 0;
                    cursor: default;
                    align-items: baseline;
                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .td.boldType {
                    font-family: $cisco-bold;
                }
                .actionsDropDown {
                    display: flex;
                    justify-content: flex-end;
                    overflow: 0;
                }
            }
        }
        .shipmentRowFull:hover {
            background-color: $light-grey-2;
            cursor: pointer;
        }
        .shipment-document:hover{
             background-color: $light-grey-2;
        }
        .shipmentRowActive {
            background-color: rgba($color: #099ad6, $alpha: 0.2);
        }
    }
    .mini-shipment {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid $gray-opacity;
        .columns {
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
        }
        .main {
            font-family: $cisco-bold;
            color: $dark-grey-1;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .secondary {
            font-family: $cisco-regular;
            color: $dark-grey-1;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .light {
            font-family: $cisco-light;
            color: $dark-grey-1;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .normal {
            font-family: $cisco-regular;
            font-size: 12px;
        }
    }
}
.textBold {
    font-family: $cisco-bold;
}
.shipmentsListCardFull {
    // overflow: hidden;
    height: 100%;
    max-height: none;
}
.shipmentsListCardFull-table-rlsp {
    overflow: visible;
    height: 100%;
    max-height: none;
}
.no-border{
    min-height: auto;
}
.shipmentsNoData {
    height: 364px;
    width: 100%;
    border-radius: 8px;
    background-color: #fbfbfd;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconContainer {
        margin: 0 24px;
    }
    .textNoData {
        h1 {
            font-size: 18px;
            margin-bottom: 2px;
        }
        p {
            margin: 0;
        }
    }
}

.shipmentsNoDataSmall {
    height: 192px;
}

@media only screen and (max-width: 1199px) {
    .shipmentsListCard {
        padding: 0px;
    }
}

@media only screen and (max-width: 62em) {
    .shipmentsListM {
        background-color: transparent;
        border: none;
        border-radius: none;
        .mini-shipment-list {
            display: flex;
            flex-direction: column;
            padding: 16px;
            border-radius: 2px;
            margin-bottom: 1rem;
            background-color: $white;        
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);   
            .shipment-id {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 0.5rem;
                .main {
                    font-family: $cisco-bold;
                    color: $dark-grey-1;
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .flex-title{
                    flex: auto;
                }
            }
            .shipment-info {
                padding-top: 1rem;
                padding-bottom: 0.5rem;
                border: solid #333;
                border-width: 1px 0 1px 0;
                .info-column {
                    display: flex;
                    flex-direction: column;
                    span {
                        margin: 4px 0;
                    }
                    .light {
                        font-family: $cisco-light;
                        color: $dark-grey-1;
                        font-size: 12px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .normal {
                        font-family: $cisco-regular;
                        font-size: 12px;
                        p { margin: 0; }
                    }
                }
                .center-content{
                    justify-content: flex-end !important;
                }
            }
            .shipment-row{
                border: none;
            }
            .shipment-details {
                label.showDetails {
                    width: 100%;
                    cursor: pointer;
                    margin-top: 12px;
                    text-align: right;
                    color: $cisco-blue;
                }
            }
        }
    }
 
}

