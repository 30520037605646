.table-form{
    th {
        font-family: $cisco-light;
        color: $grey;
        font-size: 12px;
        border-top: 0;
    }
    td {
        vertical-align: middle;
        .inputControlWrapper {
            padding: 0;
            .inputControl {
                height: auto;
            }
        }
        div {
            .part-desc {
                margin-left: 8px;
            }
        }
        p {
            margin: 0;
        }
    }
}

.card-ci {
    .inputControlWrapper {
        padding: 0;
        .inputControl  {
            height: auto;
        }
    }
}