.schedule-container {
    padding: 24px 32px;
}

.schedule-taskbar {
    .schedule-waybill {
        .btnsContainer {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .schedule-container {
        padding: 8px;
    }
}
