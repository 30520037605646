@import '../../vars';

.dropDown {
    position: relative;
    width: min-content;
    .link-decoration{
        text-decoration: none;
    }
    .dropDownBtn {
        margin: 0;
        cursor: pointer;
    }
    .dropDownContent {
        display: block;
        position: absolute;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid;
        border-color: $light-grey-1;
        background-color: $white;
        right: 0;
        z-index: 10;
        .dropDownOption {
            display: flex;
            align-items: center;
            padding: 12px;
            border-bottom: solid 1px $light-grey-1;
            color: $grey;
            cursor: pointer;
            white-space: nowrap;
            .icon {
                margin-right: 12px;
            }
            label {
                margin: 0 0 0 8px;
                white-space: nowrap;
                cursor: pointer;
            }
        }
        .dropDownOption::hover, .dropDownOption:focus, .dropDownOption:active {
            text-decoration: none;
            color: $grey;
        }
    }
    .dropDownContentHide {
        display: none;
    }
}