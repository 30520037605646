.requestReturnPageContainer {
    width: 100%;
    height: 100%;
    padding: 32px;
    background-color: $light-grey-3;
    overflow: auto;
    max-height: calc(100vh - 124px);
    .flowRow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        .flowOptionsContainer {
            display: flex;
            // width: 20%;
            align-items: center;
            justify-content: space-around;
            min-width: 15%;
            cursor: pointer;
            .step-icon {
                width: 30px;
            }
            .flowOption {
                margin: 0;
                width: calc(100% - 2rem);
                padding-left: 5px;
                padding-right: 2px;
            }
        }
        .arrow {
            width: 30px;
        }
        .flowOptionsContainer.disabled {
            cursor: default;
        }
    }
    .container-request-return {
        padding-bottom: 32px;
    }
}

@media only screen and (max-width: 1199px) {
    .requestReturnPageContainer {
        height: auto;
        width: 100%;
        padding: 8px;
        .container-request-return {
            padding-bottom: 8px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .requestReturnPageContainer {
        max-height: none;
        overflow: hidden;
    }
}
