.main-settings {
    .main-container {
        width: 100%;
        padding: 24px;
        .setting-header {
            color: $grey;
        }
    }
    .toolbar-settings {
        position: relative;
        height: 100%;
        padding: 32px 32px;
        box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.17);
        background-color: $white;
        display: flex;
        flex-direction: column;
        .toolbar-container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .toolbar-buttons {
            display: flex;
            justify-content: center;
            padding-top: 16px;
            .cancel {
                margin-right: 4px;
            }
            .save {
                margin-left: 4px;
            }
        }
    }
    .settings-card {
        min-height: 126px;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid $light-grey-1;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
    .additional-settings-card {
        min-height: 126px;
        // height: 18%;
        box-sizing: border-box;
        border: 1px solid $light-grey-1;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        padding: 11px;
    }
}

@media only screen and (max-width: 1199px) {
    .main-settings {
        .main-container {
            padding: 0;
        }
        .toolbar-settings {
            padding: 16px;
        }
    }
}
