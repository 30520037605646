@import "../../vars";

.checkRadioContainer {
    height: 19px;
    color: $dark-grey-1;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    width: min-content;
    white-space: nowrap;
    max-width: 50%;
    padding-right: 32px;
    .inputCheckRadio:checked,
    .inputCheckRadio:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    .inputCheckRadio:checked + .checkRadioLabel,
    .inputCheckRadio:not(:checked) + .checkRadioLabel {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $dark-grey-1;
    }
    .inputCheckRadio:checked + .checkRadioLabel:before,
    .inputCheckRadio:not(:checked) + .checkRadioLabel:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border-width: 1px;
        border-style: solid;
        border-color: $light-grey-1;
        border-radius: 100%;
        background: $white;
    }

    .inputCheckRadio:checked + .checkRadioLabelError:before,
    .inputCheckRadio:not(:checked) + .checkRadioLabelError:before {
        border-color: $red;
    }

    .inputCheckRadio:checked + .checkRadioLabel:after,
    .inputCheckRadio:not(:checked) + .checkRadioLabel:after {
        content: "";
        width: 10px;
        height: 10px;
        background: $white;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .inputCheckRadio:checked.inputCheckRadio:not(:disabled) + .checkRadioLabel:before {
        border-color: $cisco-blue;
        background-color: $cisco-blue;
    }

    .inputCheckRadio:not(:checked) + .checkRadioLabel:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .inputCheckRadio:checked + .checkRadioLabel:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .inputCheckRadio:disabled + .checkRadioLabel::before {
        background-color: $light-grey-2;
    }

    .inputCheckRadio:disabled + .checkRadioLabel::after {
        background-color: $cisco-blue;
    }
}

.checkRadioContainer:hover {
    .inputCheckRadio:not(:checked).inputCheckRadio:not(:disabled) + .checkRadioLabel:before {
        border-width: 1px;
        border-style: solid;
        border-color: $cisco-blue;
        background-color: $light-grey-2;
    }
}

.checkRadioContainer:focus,
.checkRadioContainer:active {
    .inputCheckRadio:checked.inputCheckRadio:not(:disabled) + .checkRadioLabel:before,
    .inputCheckRadio:not(:checked).inputCheckRadio:not(:disabled) + .checkRadioLabel:before {
        box-shadow: 0 0 2px 1px $cisco-blue !important;
    }
}
