@import '../../vars';

.infoContainer {
    position: relative;
    width: 16px;
    margin: 0 auto;
    .infoBtn {
        margin: 0;
        cursor: pointer;
    }
    .infoContent {
        display: none;
        align-items: center;
        width: 321px;
        position: absolute;
        top: -16px;
        left: 28px;
        padding: 12px;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid;
        border-color: $light-grey-1;
        background-color: $white;
        z-index: 10;
        label {
            margin: 0;
            margin-left: 12px;
            width: calc(100% - 40px);
        }
    }
    .infoContentLeft {
        left: -4px;
        transform: translate(-100%);
        width: 270px;
    }
}
.infoContainer:hover {
    .infoContent {
        display: flex;
    }
}

@media only screen and (max-width: 1199px) {
    .infoContainer {
        .infoContent {
            left: auto;
            right: 0;
        }
    }
}
