@import "../vars";

.selectPartsWrapper {
    .selectPartsTitleHead {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;
        .descripction {
            width: 67%;
        }
        .inputControlWrapper,
        .inputControl {
            margin: 0;
            padding: 0;
        }
    }
    .selectPartsContainer {
        box-sizing: border-box;
        border: 1px solid rgba(205, 208, 227, 0.3);
        border-radius: 8px;
        background-color: $white;
        // height: 60vh;
        overflow: auto;
        table {
            margin: 24px;
            // margin-top: 0px;
            width: calc(100% - 48px);
            thead {
                tr {
                    position: sticky;
                    top: 0;
                    background-color: white;
                    z-index: 10;
                }
                .packagesTitles {
                    width: 100%;
                    height: 41px;
                    border: none;
                    border-bottom: 2px solid #e1e2eb;
                    th {
                        cursor: pointer;
                        height: 19px;
                        color: #a6a5a5;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                        font-weight: normal;
                    }
                }
            }
            tbody {
                tr {
                    height: 56px;
                    td {
                        border-bottom: 1px solid #e1e2eb;
                    }
                    td.noBorder {
                        border: none;
                    }
                    .inputControlWrapper {
                        width: 100px;
                        .inputControl {
                            justify-content: center;
                        }
                    }
                    .showLinesIcon {
                        cursor: pointer;
                    }
                }
                .packageSelect {
                    .headPackage {
                        h4 {
                            color: #666666;
                            margin: 0;
                        }
                    }
                }
                .packagesRow {
                    width: 100%;
                    td {
                        text-align: center;
                        .inputControlWrapper {
                            width: 100px;
                            padding: 0;
                            .sizeInput {
                                height: 2rem;
                                width: 5rem;
                                min-width: 5rem !important;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .selectPartsWrapper {
        .packageSelect {
            padding-left: 30px;
            .inputControlWrapper {
                padding: 0;
                .inputControl  {
                    height: auto;
                    .inputContainer {
                        padding: 0;
                        input {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
