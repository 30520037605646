.requestReturnMaxWidth {
    max-width: 1980px;

}

.situationCard {
    h4 {
        font-size: 12px;
    }

    label {
        font-size: 0.70rem;
        vertical-align: top;
    }
}

.reportMenuPageContainer {
    width: 100%;
    height: 100%;
    padding: 32px;
    background-color: $white;
    overflow: auto;
    max-height: calc(100vh - 124px);
    min-height: 89vh;
.card-container{
   

    .report-card{
        background-color: $light-grey-3;
        padding: 1rem;
        margin-top: 1rem;
        height: 100%;
        cursor: pointer;
    }
}
    // .flowRow {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin-bottom: 24px;

    //     .flowOptionsContainer {
    //         display: flex;
    //         // width: 20%;
    //         align-items: center;
    //         justify-content: space-around;
    //         min-width: 15%;
    //         cursor: pointer;

    //         .step-icon {
    //             width: 30px;
    //         }

    //         .flowOption {
    //             margin: 0;
    //             width: calc(100% - 2rem);
    //             padding-left: 5px;
    //             padding-right: 2px;
    //         }
    //     }

    //     .arrow {
    //         width: 30px;
    //     }

    //     .flowOptionsContainer.disabled {
    //         cursor: default;
    //     }

    // }

    // .returnFlowRow {
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: 24px;

    //     .flowOptionsContainer {
    //         display: flex;
    //         // width: 20%;
    //         align-items: center;
    //         justify-content: space-around;
    //         min-width: 15%;
    //         cursor: pointer;

    //         .step-icon {
    //             width: 30px;
    //         }

    //         .flowOption {
    //             margin: 0;
    //             padding-left: 5px;
    //             padding-right: 2px;
    //         }
    //     }

    //     .arrow {
    //         width: 30px;
    //     }

    //     .flowOptionsContainer.disabled {
    //         cursor: default;
    //     }
    // }

    // .container-request-return {
    //     padding-bottom: 32px;
    // }
}


@media only screen and (max-width: 1366px) {
    .situationCard {
        h4 {
            font-size: 10px;
        }

        label {
            font-size: 0.60rem;
        }
    }
}

@media only screen and (min-height: 800px) {
    .situationCard {
        padding: 1rem !important;
        margin-top: 1rem !important;

        img {
            height: 30px !important;
        }

        svg {
            font-size: 30px !important;
        }

        h4 {
            font-size: 14px;
            margin-top: 8px !important;
            margin-bottom: 4px !important;
        }

        label {
            font-size: 0.75rem;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .reportMenuPageContainer {
        height: auto;
        width: 100%;
        padding: 8px;

        .container-request-return {
            padding-bottom: 8px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .reportMenuPageContainer {
        max-height: none;
        overflow: hidden;

        .returnFlowRow {
            width: 70%;
            padding: 2px !important;
            margin-left: 4vw;
        }
    }
}

@media only screen and (max-width: 3840px) {
    .requestReturnMaxWidth {
        max-width: 1980px;
        margin: 0 auto;
    }
}