@import "../vars";

.loginPageContainer {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    .welcome {
        width: 58%;
        padding: 70px 8% 16px 8%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-color: rgba(0, 50, 72, 0.71);
        background-image: url('../../static/img/loginBackgroung.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        .ciscoLogo {
            width: 76px;
            height: 40px;
        }
        .welcomeDesc {
            h2 {
                color: $white;
                margin: 0;
                margin-bottom: 0.5rem;
                width: fit-content;
                font-weight: bold;
            }
            p {
                font-size: 0.75rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: $light-grey-1;
                margin: 0;
            }
        }
        .ciscoPOWRApp {
            .ciscoPOWRdownload {
                font-size: 0.675rem;
                line-height: 2;
                color: #099ad6;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                padding: 0 12px;
            }
            .qrDownload {
                background-color: white;
                width: 72px;
                height: 72px;
            }
            .ciscoPOWRContent {
                display: flex;
                flex-direction: column;
                min-height: 72px;
                justify-content: space-evenly;
            }
            .ciscoPOWRtitle {
                font-size: 16px;
                font-weight: bold;
                color: #099ad6;
            }
            .ciscoPOWRStoreLogoCont {
                font-size: 0.675rem;
                line-height: 2;
                color: #099ad6;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .ciscoPOWRStoreLogoContAlert {
                text-align: center;
            }
            .ciscoPOWRDesc {
                padding-top: 20px;
                font-weight: bold;
                color: white;
            }
            .ciscoPOWRMobileAlert {
                text-align: center;
                padding: 10px 10px;
                font-size: 13px;
                color: black;
                background: #ffb600;
            }
        }
        .welcomeFooter {
            p {
                height: 1.5rem;
                font-family: 'CiscoSansTTLight-Oblique', sans-serif;
                font-size: 0.625rem;
                font-weight: 300;
                font-stretch: normal;
                font-style: oblique;
                line-height: 2.4;
                letter-spacing: normal;
                color: $white;
                margin: 0;
            }
            .copy {
                font-size: 0.75rem;
            }
        }
    }
    .loginPart {
        width: 42%;
        padding: 28px 8% 24px 7%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .lngControls {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 19px;
            font-size: 14px;
            color: $dark-grey-1;
            .icon {
                margin-left: 8px;
                opacity: 0.6;
            }
        }
        h2 { margin-bottom: 0.5rem;}
        .rmaMessage {
            min-height: 32px;
            width: 253px;
            color: $grey;
            font-size: 11px;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;
            margin-bottom: 0.5rem;
        }
        .rfcLoginMessage{
            margin: 0;
            color: $red;
            text-align: center;
        }

        .loginForm {
            width: 100%;
            display: flex;
            flex-direction: column;
            label {
                height: 3.5rem;
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
                .text {
                    width: 100%;
                    height: 1.5rem;
                    font-size: 14px;
                    line-height: 1.71;
                    letter-spacing: 0.13px;
                    color: $dark-grey-1;
                    border-width: 0 0 1px 0;
                    border-color: $light-grey-1;
                }
                .text::placeholder {
                    color: $light-grey-1;
                    opacity: 1; 
                }
                .text:-ms-input-placeholder { 
                    color: $light-grey-1;
                }
                .text::-ms-input-placeholder { 
                    color: $light-grey-1;
                }
                .error {
                    border-color: $red;
                }
                p {
                    height: 1rem;
                    font-size: 0.75rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: 0.4px;
                    color: $red;
                }
            }
            .submit {
                margin: 12px 0;
                width: 100%;
                height: 2.5rem;
                border-radius: 20px;
                border: solid 1px $cisco-blue;
                background-color: $cisco-blue;
                font-size: 1rem;
                text-align: center;
                color: $white;
            }
            .submit:disabled {
                border: solid 1px $light-grey-1;
                background-color: $light-grey-1;
            }
        }
        .or {
            margin: 8px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .bar {
                width: 75px;
                height: 0;
                border-bottom: solid 1px $light-grey-1;
                margin-bottom: 2px;
            }
            h6 {
                height: 19px;
                font-size: 14px;
                color: $cisco-blue;
                margin: 0 16px;
            }
        }
        .singInCisco {
            width: min-content;
            padding: 32px 48px;
            margin-bottom: 12px;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid;
            border-color: $light-grey-1;
            background-color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            img {
                height: 48px;
            }
            p {
                white-space: nowrap;
                font-size: 20px;
                color: $dark-grey-1;
                margin: 0;
                margin-top: 1.5rem;
                font-weight: bold;
            }
        }
        .singInCiscoSmall {
            padding: 14px 28px;
            img {
                height: 42px;
            }
            p {
                font-size: 12px;
            }
        }
        .comments {
            width: 100%;
            margin: 6px 0;
            height: 2rem;
            p {
                margin: 0;
                font-size: 0.675rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: normal;
                color: $grey;
            }
        }
        .footLinks {
            width: 100%;
            height: 1.5rem;
            font-size: 0.675rem;
            line-height: 2;
            color: $cisco-blue;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            a {
                margin: 0;
            }
            a:visited {  
                color: $cisco-blue;
            }
        }
    }
}